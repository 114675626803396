<template>
  <div>
    <ContactUs></ContactUs>
  </div>
</template>
<script>
import ContactUs from "@/components/ContactUs.vue";
export default {
  name: "Contact",
  components: { ContactUs },
};
</script>
<style lang=""></style>
