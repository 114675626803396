<template>
  <Profile></Profile>
</template>

<script>
import Profile from "@/components/profile/profile.vue";
export default {
  name: "ProfileForm",
  components: {
    Profile,
  },
};
</script>

<style scoped></style>
