<template>
  <div class="pageh">
    <!-- intro -->
    <div class="intro pt-5 pb-0">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <h1 class="text-md-start px-3 px-md-0 text-center head-text fw-bold display-3 mt-0 mt-xl-4">
                Welcome to EdigenomiX Academy
              </h1>
              <h3 class="text-md-start px-3 px-md-0 text-center fw-bold mt-0 mt-xl-4">
                Elevate Your Research Journey with Us!
              </h3>
              <p class="text-md-start text-center headp">
                At EdigenomiX Academy, we provide the ultimate training to master the art of clinical research and
                successfully publish your work in prestigious international medical journals.
                Learn, Conduct, and Publish Research – All in One Platform!
                Our comprehensive program ensures you gain the skills needed to excel in research, from initial concept
                to publication. After completing the course, graduates who join our research team receive ongoing,
                collaborative support and mentoring at no additional cost.
                Register Now and Secure Your Spot! </p>
              <div class="action gap-5">
                <div class="btn btn-purple rounded-pill px-4 me-3">
                  Enroll Now
                </div>
                <div class="btn btn-outline-purple rounded-pill border">
                  What's EdigenomiX
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="photo mt-4 mt-sm-0">
              <img class="intro-img mw-100 d-block mx-auto" src="../assets/courses/course-doc.gif" alt="" />
              <!-- <div class="chip-1 shadow">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#623de2"
                  class="bi bi-arrow-up-left-circle-fill" viewBox="0 0 16 16">
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-5.904 2.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707z" />
                </svg>
                <div class="text-center">
                  <h6 class="m-0 fw-bold">+</h6>
                  <p class="m-0 fs-small">Register Now</p>
                </div>
              </div>
              <div class="chip-2 shadow">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#623de2" class="bi bi-palette"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  <path
                    d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8m-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7" />
                </svg>
                <div class="text-center">
                  <h6 class="m-0 fw-bold">Biomedical Sciences</h6>
                  <p class="m-0 fs-small">Join EdigenomiX Academy</p>
                  <button class="btn btn-sm mt-2 btn-purple">Join now</button>
                </div>
              </div>
              <div class="chip-3 shadow">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#623de2"
                  class="bi bi-arrow-up-left-circle-fill" viewBox="0 0 16 16">
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-5.904 2.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707z" />
                </svg>
                <div class="text-center">
                  <h6 class="m-0 fw-bold">50+</h6>
                  <p class="m-0 fs-small">Online Courses</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- recent-subject  -->
    <div class="recent-subject bg-light" id="categories">
      <div class="container pb-5 pt-0">
        <div class="container-fluid py-5">
          <div class="view d-flex justify-content-between align-items-center">
            <h1 class="display-5 fs-1 fw-bold">popular subjects</h1>
          </div>
          <p class="col-md-8 fs-6">
            Online learning offers a new way to explore subjects you're
            passionate about
          </p>
        </div>
        <div class="container mx-md-4 mx-0">
          <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4">
            <div class="col">
              <div class="card">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#62cda2"
                  class="bi bi-laptop subject-icon ms-3 mt-3 firt" viewBox="0 0 16 16">
                  <path
                    d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title">Biomedical Sciences</h5>
                  <p class="card-text">02 COURSES</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fbca7f"
                  class="bi bi-clipboard-data subject-icon ms-3 mt-3 seco" viewBox="0 0 16 16">
                  <path
                    d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0z" />
                  <path
                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                  <path
                    d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title">Genomics and Genetics</h5>
                  <p class="card-text">06 COURSES</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#e54b7f"
                  class="bi bi-gear subject-icon ms-3 mt-3 fiv" viewBox="0 0 16 16">
                  <path
                    d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                  <path
                    d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title">Bioinformatics</h5>
                  <p class="card-text">06 COURSES</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#358df7"
                  class="bi bi-binoculars subject-icon ms-3 mt-3 thir" viewBox="0 0 16 16">
                  <path
                    d="M3 2.5A1.5 1.5 0 0 1 4.5 1h1A1.5 1.5 0 0 1 7 2.5V5h2V2.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5v2.382a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V14.5a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 14.5v-3a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5v3A1.5 1.5 0 0 1 5.5 16h-3A1.5 1.5 0 0 1 1 14.5V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882zM4.5 2a.5.5 0 0 0-.5.5V3h2v-.5a.5.5 0 0 0-.5-.5zM6 4H4v.882a1.5 1.5 0 0 1-.83 1.342l-.894.447A.5.5 0 0 0 2 7.118V13h4v-1.293l-.854-.853A.5.5 0 0 1 5 10.5v-1A1.5 1.5 0 0 1 6.5 8h3A1.5 1.5 0 0 1 11 9.5v1a.5.5 0 0 1-.146.354l-.854.853V13h4V7.118a.5.5 0 0 0-.276-.447l-.895-.447A1.5 1.5 0 0 1 12 4.882V4h-2v1.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5zm4-1h2v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm4 11h-4v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-8 0H2v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5z" />
                </svg>
                <div class="card-body">
                  <h5 class="card-title">Biostatistics</h5>
                  <p class="card-text">02 COURSES</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- top-courses  -->
    <div class="top-courses bg-main" id="courses">
      <div class="container py-5">
        <div class="py-4 container-fluid">
          <h1 class="display-5 fs-1 fw-bold">Find the right course for you</h1>
        </div>
        <div class="courses py-4">
          <div class="courses-all row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-4 mx-xl-5">
            <div class="col showc all web" v-for="course in allCourses">
              <div class="card border-0 p-2 py-3 h-100">
                <div class="row w-100 pt-2 mx-0 align-items-center">
                  <div class="col">
                    <img :src="course.image" class="card-img-top rounded" alt="..." />
                  </div>
                  <div class="col text-end">
                    <h3>{{ course.price }} $</h3>
                  </div>
                </div>
                <div class="card-body postion-relative mb-4">
                  <p class="text-capitalize fs-4 fw-semibold hints">
                    {{ course.en_name }}
                  </p>
                  <h5 class="card-title text-capitalize">
                    {{ course.en_content }}
                  </h5>
                  <p class="card-text">
                    {{ course.en_description }}
                  </p>
                  <div class="row my-3">
                    <div class="col">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"
                        class="bi bi-file-earmark-fill me-2" viewBox="0 0 16 16">
                        <path
                          d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                      </svg>
                      {{ course.lessons_number }} lessons
                    </div>
                    <div class="col">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"
                        class="bi bi-people-fill me-2" viewBox="0 0 16 16">
                        <path
                          d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                      </svg>
                      {{ course.students_number }} student
                    </div>
                  </div>
                  <!-- modal button  -->
                  <button type="button" class="btn btn-purple course-btn" data-bs-toggle="modal"
                    data-bs-target="#takenCourseModal">
                    Enroll Now
                  </button>

                  <!-- Modal drop -->
                  <div class="modal fade" id="takenCourseModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            {{ course.en_name }}
                          </h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <!-- form  -->
                          <form>
                            <div class="mb-3">
                              <label for="username" class="form-label">Your Name</label>
                              <input type="text" class="form-control" id="username" aria-describedby="user"
                                v-model="name" />
                              <div id="user" class="form-text text-danger" v-if="takenError.name">
                                {{ takenError.name[0] }}
                              </div>
                            </div>

                            <div class="mb-3">
                              <label for="emailadress" class="form-label">Email address</label>
                              <input type="email" class="form-control" id="emailadress" aria-describedby="email"
                                v-model="email" />
                              <div id="email" class="form-text text-danger" v-if="takenError.email">
                                {{ takenError.email[0] }}
                              </div>
                            </div>

                            <div class="mb-3">
                              <label for="phone" class="form-label">Phone Number</label>
                              <input type="number" class="form-control" id="phone" aria-describedby="allo"
                                v-model="phone" />
                              <div id="allo" class="form-text text-danger" v-if="takenError.mobile">
                                {{ takenError.mobile[0] }}
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" id="Close" data-bs-dismiss="modal">
                            Close
                          </button>
                          <button type="button" class="btn btn-purple" @click="takenCourse(course.id)">
                            Taken Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "Services",
  data() {
    return {
      allCourses: [],
      // course taken
      name: "",
      email: "",
      phone: null,
      courseData: [],
      takenError: [],
    };
  },
  mounted() {
    this.allCoursesData();
  },
  methods: {
    async allCoursesData() {
      let result = await axios.get(
        `${process.env.VUE_APP_API_URL}/courses-list`
      );
      if (result.status == 200) {
        this.allCourses = result.data.data.courses;
        // console.log(this.allCourses);
      }
    },
    // course taken
    async takenCourse(course_id) {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("mobile", this.phone);
      formData.append("course_id", course_id);

      await axios
        .post(`${process.env.VUE_APP_API_URL}/register-course`, formData)
        .then((res) => {
          this.courseData = res.data.data;
          document.getElementById('Close').click();
          toast.success("Taken Course Success", {
            autoClose: 1000,
          });
        })
        .catch((err) => {
          console.log(err.response.data.error);
          this.takenError = err.response.data.error;
          toast.error("Taken Faild", {
            autoClose: 1000,
          });
        });
    },
  },
};
</script>

<style scoped>
.fs-small {
  font-size: 15px;
}

.intro .head-text {
  max-width: 500px;
}

.intro .headp {
  max-width: 700px;
}

.intro .action {
  width: fit-content;
}

.intro .photo {
  position: relative;
}

.intro .photo .intro-img {
  margin: 0 0 0 -3rem;
  max-height: 600px;
  min-width: 400px;
}

.intro .chip-1 {
  width: 200px;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  background-color: #eeddfae0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  position: absolute;
  top: 90px;
  left: 90px;
}

.intro .chip-2 {
  width: 300px;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  background-color: #eeddfae0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  position: absolute;
  bottom: 10px;
}

.intro .chip-3 {
  width: 200px;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  background-color: #eeddfae0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  position: absolute;
  right: 40px;
  bottom: 130px;
}

/* recent-subject */

.recent-subject .card {
  height: 100%;
  min-height: 139px !important;
  transition: 0.4s;
  box-shadow: 0 0 4px 0px #b3bac1;
  border: none !important;
}

.recent-subject .card:hover {
  box-shadow: 3px 4px 14px 0px #90a9c1;
}

.subject-icon {
  font-size: 20px;
  text-align: center;
  background-color: lightblue;
  border-radius: 10px;
  padding: 13px;
  width: 60px;
  height: 50px;
}

.recent-subject .card .firt {
  background-color: #ddf7eb;
  color: #62cda2;
}

.recent-subject .card .seco {
  background-color: #f3e9d7;
  color: #fbca7f;
}

.recent-subject .card .thir {
  background-color: #dae7f5;
  color: #358df7;
}

.recent-subject .card .fiv {
  background-color: #f5dce4;
  color: #e54b7f;
}

/* Top-courses */

.courses {
  transition: 1s;
}

.hints {
  color: #4171de;
}

.dropdown {
  display: none;
}

@media (max-width: 1000px) {
  .dropdown {
    display: block;
  }
}

.course-btn {
  background-color: #967cec !important;
  position: absolute;
  bottom: 1rem;
}

.course-btn:hover {
  box-shadow: -3px 5px 0px 600px #ae93f845;
}

.top-courses .col .card {
  transition: 0.4s;
  overflow: hidden;
}

.top-courses .showc {
  min-height: 450px;
}

/* top-institutions */
.grad {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0) 70%, #f8f9fa 70%);
}

.top-institutions .video {
  max-width: 1000px;
  /* max-height:610px ; */
  margin: auto;
}

.video-img {
  border-radius: 20px;
  /* background-image: url(../assets/videos/video-image.jpg); */
  background-size: cover;
  /* max-height: 463px; */
}

@media (max-width: 1100px) {
  .top-institutions .video {
    max-width: 700px;
  }
}

@media (max-width: 787px) {
  .intro .action {
    margin: auto;
  }

  .intro .head-text {
    margin-top: -10px;
  }

  .intro .photo .intro-img {
    margin: 0 0 0 0rem;
    min-width: 300px;
  }

  .intro .chip-1 {
    top: 250px;
    left: 0px;
  }
}
</style>
